<template>
  <div class="resource-list">
    <h2 class="resource-list__title">Resources</h2>
    <div class="resource-list__container">
      <ResourceItem
        v-for="resource in resources"
        :key="resource.name"
        :name="resource.name"
        :link="resource.link"
        :content="resource.content"
      ></ResourceItem>
    </div>
  </div>
</template>
<script>
import ResourceItem from '@/components/ResourceItem.vue';

export default {
  name: 'ResourceList',
  props: {
    resources: { type: Object, required: true },
  },
  components: {
    ResourceItem,
  },
};
</script>
<style scoped lang="scss">
.resource-list {
  display: flex;
  flex-direction: column;
  // padding: 3rem;
  &__title {
    text-transform: uppercase;
    font-family: $font-family-block;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.625rem;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 1.5rem;
  }
}
@include media-breakpoint-up(md) {
  .resource-list {
    &__title {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
}
@include media-breakpoint-up(lg) {
  .resource-list {
    flex-direction: row;
    padding: 0;
    &__title {
      margin-right: 6rem;
      font-size: 3rem;
      line-height: 3.3rem;
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 6rem;
    }
  }
}
</style>
