<template>
  <div class="resource">
    <a :href="link" class="resource__link">
      <div class="resource__name">{{ name }} <i aria-hidden="true" class="bi bi-chevron-right"></i></div>
      <p v-if="content" class="resource__content">{{ content }}</p>
    </a>
  </div>
</template>
<script>
export default {
  name: 'ResourceItem',
  props: {
    name: { type: String, required: true },
    link: { type: String, required: true },
    content: { type: String, required: false },
  },
};
</script>
<style scoped lang="scss">
.resource {
  border-top: 1px solid $light-gray;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;

  &__name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    @include heading-base;
    text-transform: none;
    font-size: 1.25rem;
    line-height: 1;
  }
  &__content {
    color: $black;
    // font-family: $font-family-serif;
    font-size: 0.875rem;
    font-weight: $font-weight-light;
    line-height: 1.75;
  }
}
@include media-breakpoint-up(md) {
  .resource {
    &__name {
      font-size: 1.75rem;
    }
    &__content {
      font-size: 1.125rem;
    }
  }
}
</style>
